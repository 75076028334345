import React from 'react'
import Lottie from 'react-lottie';

import animationData from '../assets/lottie/Lottie_logo.json'
import {Overlay, Container} from './styles/fullScreenLoader';

const FullScreenLoader = ({opacity = 1}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
    };

    return (
        <>
        <Overlay opacity={opacity}/>
        <Container>
            <Lottie options={defaultOptions}/>
        </Container>
        </>
    );
}

export default FullScreenLoader;