import { useState, useEffect, useContext } from "react";
import useImage from "use-image";
import queryString from "query-string";
import { get } from "lodash";

import {
  getTranslations,
  getContent,
  getContentForIFrame,
  getFrames,
  getDefaultContentWithConfiguration,
  getLocationsLibraryById,
  getShoutoutByLocationId,
  getContentByLocationShortId,
  getLocationsLibraryByShortId,
} from "../actions";
import { ContentContext, TranslationsContext, MainContext, LibraryContext, ShoutoutContext, LocationFramesContext, CampaignContext } from "../context";
import { CUSTOM_FRAME_TYPES_LIST, urls } from "../constants";
import { setLocStorage, getCookie } from "../utils/Utils";

// todo(vmyshko): split logic into separate hooks?
export function useTheme(props) {
  const { main, setMain } = useContext(MainContext);
  const { content, setContent } = useContext(ContentContext);
  const { setTranslations } = useContext(TranslationsContext);
  const { setLibrary } = useContext(LibraryContext);
  const { setShoutout } = useContext(ShoutoutContext);
  const { setLocationFrames } = useContext(LocationFramesContext);
  const { setCampaign } = useContext(CampaignContext);

  const values = queryString.parse(props.location.search);

  const {
    //
    iFrame,
    locationId: locId,
    templateId,
    shortId: shortIdState,
    addr: addrState,
    token: tokenState,
    demo: demoState,
  } = get(props, "location.state", {});

  const token = values.token || tokenState;
  const addr = values.addr || addrState;
  const shortId = values.code || shortIdState;
  const demo = !!values.demo || demoState;

  const preview = window.location.pathname === "/preview";

  function receiveMessage(event) {
    // TODO: make sure that the event origin is allowed
    const { data, libraryRipples = [] } = JSON.parse(decodeURIComponent(event.data));
    const content = JSON.parse(data.content);
    const toReturn = {
      ...content,
      mobileq: data.mobileq.uploadUrl,
      fence: data.mobileq.fence,
      locationId: data.locationId,
      locationName: data.locationName,
      configuration: data.configuration,
    };
    const { configuration } = toReturn;
    const libRipples = { ripples: libraryRipples };

    setMain((prevMain) => ({ ...prevMain, configuration, preview: true }));
    setContent(toReturn);
    setLibrary(libRipples);
  }

  useEffect(() => {
    function resolve(from, to) {
      const resolvedUrl = new URL(to, new URL(from, "resolve://"));
      if (resolvedUrl.protocol === "resolve:") {
        // `from` is a relative URL.
        const { pathname, search, hash } = resolvedUrl;
        return pathname + search + hash;
      }
      return resolvedUrl.toString();
    }

    // no params
    if (!iFrame && !token && !addr && !shortId && !preview) {
      window.location = resolve(window.location.origin, "/");
      return;
    }

    // get translations
    getTranslations().then((trans) => setTranslations(trans));

    if (preview) {
      // event listener for message event -- comes from Home  window.parent.postMessage
      window.addEventListener("message", receiveMessage, false);
      return;
    }

    let privacyCookie = getCookie("privacy");
    //get Frames, Content and Shoutouts

    const appendShortIdToHomeState = shortId => props.history.push({state:{shortId,...props.history.location.state}})

    getContentByType({ addr, token, shortId, iFrame, locationId: locId, templateId })
      .catch((err) => null)
      .then(async (content) => {
        if (!content) return null;
        const { configuration, locationId,shortId } = content;
        appendShortIdToHomeState(shortId)       
        setMain((prevMain) => ({ ...prevMain, configuration }));
        setContent(content);

        if (content) {
          setCampaign(content.campaign);
        }

        const { isFramesEnabled } = (configuration && configuration.features) || {};

        // todo(vmyshko): move to App?
        if (isFramesEnabled) {
          const frames = await getFrames(locationId);

          setLocationFrames(frames || []);
        }

        const shoutout = locationId && (await getShoutoutByLocationId(locationId));
        const shoutoutInCookieData = getCookie("shoutout");
        const scid = shoutoutInCookieData && shoutoutInCookieData.sourceId;
        if (shoutout && !!Object.keys(shoutout).length && shoutout._id !== scid) {
          setShoutout(shoutout);
        }
      })
      .catch((e) => console.log(e));

    setMain((prevMain = {}) => ({ ...prevMain, code: shortId, token, addr, demo, ...privacyCookie, preview }));
  }, [iFrame, locId, templateId]);

  if (!iFrame) {
    setLocStorage({ main, content });
  }

  return () => {
    window.removeEventListener("message", receiveMessage);
  };
}

export function useFrame(frameUrl = "") {
  const isCustom = CUSTOM_FRAME_TYPES_LIST.includes(frameUrl);
  const [frameImage, setFrameImage] = useState(null);
  const [frameType, setFrameType] = useState(null);
  const [image, imageStatus] = useImage(isCustom ? "" : frameUrl, "Anonymous");

  useEffect(() => {
    if (!image) return;

    setFrameImage(image);
    setFrameType(null);
  }, [image]);

  useEffect(() => {
    if (!frameUrl) return;

    if (isCustom) {
      setFrameType(frameUrl);
      setFrameImage(null);
    }
  }, [frameUrl]);

  return [frameImage, frameType, isCustom, imageStatus];
}

// get content by request
async function getContentByType({ addr, token, shortId, iFrame, locationId, templateId }) {
  if (iFrame) {
    const content = await getContentForIFrame(locationId, templateId);
    return content;
  } else if (addr) {
    const point = urls.find((u) => u.code === addr);

    if (point) {
      const content = await getDefaultContentWithConfiguration();

      const defaultWithQ = {
        ...content,
        mobileq: point.address,
        locationId: point.locationId,
      };

      return defaultWithQ;
    }
  } else if (token) {
    return getContent(token);
  } else if (shortId) {
    return getContentByLocationShortId(shortId);
  } else {
    return null;
  }
}

export function useOrientationDetect() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [orientation, setOrientation] = useState("");
  const [firstInit, setFirstInit] = useState(false);

  useEffect(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);

    window.onresize = () => {
      const screen = window.screen;
      const sOrientation = (screen.orientation || {}).type || screen.mozOrientation || screen.msOrientation;
      const orientationMediaQuery = window.matchMedia("screen and (orientation:landscape)");
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);

      if (orientationMediaQuery.matches || (sOrientation && sOrientation.includes("landscape"))) {
        setOrientation("landscape");
      } else {
        setOrientation("portrait");
      }
    };
    //eslint-disable-next-line
  }, [window.innerHeight]);

  if (window.location.pathname === "/login") {
    return "portrait";
  }

  if (orientation && orientation.includes("landscape")) {
    if (firstInit) {
      setFirstInit(true);
      return "landscape";
    }
    return height / width < 0.72 ? "landscape" : "portrait";
  }

  return height < width ? "landscape" : "portrait";
}

export function useImageSize(imgRef) {
  const [width, setWidth] = useState(getWidth(imgRef));
  const [height, setHeight] = useState(getHeight(imgRef));

  useEffect(() => {
    const listener = () => {
      setWidth(imgRef);
      setHeight(imgRef);
    };
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [width, height, imgRef]);
  return { width, height };
}

export function useLoadLibrary(props) {
  const { library, setLibrary } = useContext(LibraryContext);
  const [result, setResult] = useState({});
  const state = (props.location && props.location.state) || {};
  const { shortId, token } = state;

  function getNotEmptyChannelsAndRipples(channels) {
    // todo(vmyshko): simplify this
    const ripples = [];
    const notEmptyChannels = channels.reduce((acc, channel) => {
      const channelRipples = channel.ripples.filter(({ isFrame }) => !isFrame);
      // has non-frame ripples
      if (channelRipples.length > 0) {
        acc.push(channel);

        ripples.push(
          ...channelRipples.map((ripple) => ({
            ...ripple,
            channelId: channel.id,
          }))
        );
      }
      return acc;
    }, []);

    return [notEmptyChannels, ripples];
  }

  useEffect(() => {
    //no params
    if (!shortId) return;

    //cached
    if (!!shortId && library && library.shortId === shortId && library.ripples) {
      setResult(library);
      return;
    }

    const libraryResponse = getLocationsLibraryByShortId(shortId);

    libraryResponse.then((contentParams) => {
      const { channels, surpriseChannels } = contentParams.data;

      const [notEmptyChannels, ripples] = getNotEmptyChannelsAndRipples(channels);

      const [notEmptySurpriseChannels, surpriseRipples] = getNotEmptyChannelsAndRipples(surpriseChannels);

      if (!notEmptyChannels[0] && !notEmptySurpriseChannels[0]) return;

      const result = {
        channels: notEmptyChannels,
        surprise: surpriseRipples,
        ripples,
        shortId: contentParams.data.shortId,
      };
      setLibrary(result);
      setResult(result);
    });
  }, [shortId]);

  useEffect(() => {
    if (!token) return;

    const { locationId } = JSON.parse(window.atob(token)) || {};

    // cache
    if (library && library.locationId && library.locationId === locationId) {
      setResult(library);
      return;
    }

    getLocationsLibraryById(locationId).then((contentParams) => {
      const { channels } = contentParams.data;

      const [notEmptyChannels, ripples] = getNotEmptyChannelsAndRipples(channels);

      if (!notEmptyChannels[0]) return;
      const result = {
        channels: notEmptyChannels,
        ripples,
        locationId,
      };
      setLibrary(result);
      setResult(result);
    });
  }, [token]);

  return result;
}

function getWidth(imgRef) {
  const rectPos = imgRef.getBoundingClientRec();
  return rectPos.width;
}

function getHeight(imgRef) {
  const rectPos = imgRef.getBoundingClientRec();
  return rectPos.height;
}
