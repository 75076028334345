import React, { useContext, useEffect, useState, useRef } from "react";
import { LibraryHeader, NewTitle, LibraryRipples, Container } from "../styles/libraryStyle";
import { TitleWrapper } from "../styles/editStyle";
import { BackBtn } from "../styles/commonStyle";
import { STextRoboto } from "../styles/textStyle";
import { Phrase } from "../Phrase";
import { ContentContext, LibraryContext, MainContext } from "../../context";
import { handleHistoryPush, handleHistoryReplace } from "../../utils/history";
import LibraryRipple from "./LibraryRipple";
import { GaEvents, sendGAEvent } from "../../utils/googleAnalytics";
import { get } from "lodash";

const channelWidth = 30;
const channelPadding = 10;
const channelBorder = 4;
const titleHeight = 50;
let ripplesLoading = 0;

const SelectRipple = (props) => {
  const { content } = useContext(ContentContext);
  const { library } = useContext(LibraryContext);
  const { main } = useContext(MainContext);
  const { setLibraryLoaded } = props;
  const { ripples = [] } = library;
  const ripplesListRef = useRef();
  const [showShadow, setShowShadow] = useState(true);

  const innerHeight = Math.min(window.innerWidth, window.innerHeight);
  const innerWidth = Math.min(window.innerWidth, window.innerHeight);
  const ripplesPerCol = innerWidth < 320 ? 4 : 5;
  const rippleHeight = (innerHeight - titleHeight - channelWidth - channelPadding - channelBorder) / ripplesPerCol;
  const IFrame = get(props, "history.location.state.iFrame");
  const isOneMacaron = get(props, "history.location.state.isOneMacaron");

  useEffect(() => {
    if (!ripplesListRef.current) return;
    const onScroll = () => {
      if (Math.abs(ripplesListRef.current.offsetWidth + ripplesListRef.current.scrollLeft - ripplesListRef.current.scrollWidth) <= rippleHeight) {
        setShowShadow(false);
      } else if (!showShadow) {
        setShowShadow(true);
      }
    };
    ripplesListRef.current.onscroll = onScroll;
    onScroll();
  }, [showShadow, rippleHeight, ripplesListRef.current]);

  const { general, edit = {} } = content;
  const { selectDesign } = edit.texts.instructions;

  const onRippleClick = (channelId, url, isTemplate, rippleId) => {
    if (props.history.location.state && props.history.location.state.macaronId) {
      const {macarons = {}, macaronId, macaronAmount, iframe} = props.history.location.state;
        handleHistoryPush(props.history, "/macaron", {
          ...props.history.location.state,
          macarons: {...macarons, [macaronId.toString()]: {image: url}},
          macaronAmount,
          iframe
        });

        return;
    }

    const historyState = props.history.location;
    sendGAEvent(GaEvents.DesignSelection, {
      eventCategory: "library",
      eventLabel: url,
      locationId: content.locationId,
    });

    handleHistoryReplace(props.history, "/library", {
      ...historyState,
      activeChannel: channelId,
    });

    if (isTemplate) {
      if (main.preview) {
        handleHistoryReplace(props.history, "/edit", {
          target: { orgImage: url },
          tabActions: ["frames", "texts"],
          fromLibrary: true,
          editable: true,
          rippleId: rippleId,
          isOneMacaron,
        });
      } else {
        handleHistoryPush(props.history, "/edit", {
          target: { orgImage: url },
          tabActions: ["frames", "texts"],
          fromLibrary: true,
          editable: true,
          rippleId: rippleId,
          isOneMacaron
        });
      }
    } else {
      if (main.preview) {
        handleHistoryReplace(props.history, "/edit", {
          target: { orgImage: url },
          tabActions: [],
          fromLibrary: true,
          editable: false,
          rippleId: rippleId,
          isOneMacaron
        });
      } else {
        handleHistoryPush(props.history, "/edit", {
          target: { orgImage: url },
          tabActions: [],
          fromLibrary: true,
          editable: false,
          rippleId: rippleId,
          isOneMacaron
        });
      }
    }
  };

  function goToHome() {
    if (main.preview) {
      handleHistoryReplace(props.history, "/preview");
      return;
    }

    if (props.history.location.state && props.history.location.state.macaronId) {
      const {macarons = {}, macaronAmount, iframe} = props.history.location.state;
        handleHistoryPush(props.history, "/macaron", {
          ...props.history.location.state,
          macarons,
          macaronAmount,
          iframe
        });
        return;
    }


    handleHistoryPush(props.history, "/home");
  }

  function onRippleLoad() {
    ripplesLoading += 1;
    if (ripplesLoading === ripples.length) {
      setLibraryLoaded(true);
      ripplesLoading = 0;
    }
  }

  return (
    <Container>
      <LibraryHeader>
        <TitleWrapper titleHeight={titleHeight}>
          {!IFrame && <BackBtn onClick={goToHome} colors={general.colors} />}
          <NewTitle colors={general.colors}>
            <STextRoboto>
              <Phrase Key={selectDesign ? selectDesign.translationKey : "5d2b73a6-346c-4d20-aa4a-f2c434a1ef63" } default={selectDesign && selectDesign.text ? selectDesign.text : ""} />
            </STextRoboto>
          </NewTitle>
          <div style={{ width: "10%" }} />
        </TitleWrapper>
      </LibraryHeader>
      <LibraryRipples ref={ripplesListRef} ripplesPerCol={ripplesPerCol} rippleHeight={rippleHeight} nRipples={ripples.length}>
        {ripples.map(({ previewUrl, id, url, isTemplate, channelId }, i) => (
          <LibraryRipple onRippleLoad={onRippleLoad} key={id + i} previewUrl={previewUrl} onClick={() => onRippleClick(channelId, url, isTemplate, id)} />
        ))}
      </LibraryRipples>
    </Container>
  );
};

export default SelectRipple;
