import axios from "axios";
import { getSignUrl, pushUrlToQ, getPreviewUrl } from "./getMobileQ";

export function uploadToCloudinary({fileData, locationId, rippleId, isMacaroon}) {
  return getSignUrl(locationId).then((res) => {
    if (!res.data) {
      throw new Error("sign request failed");
    }
    let { url, downloadUrl, params } = res.data;

    if (!url || !downloadUrl) {
      throw new Error("cant find sign data");
    }

    let formData = new FormData();
    formData.set("file", fileData);
    Object.keys(params).forEach((e) => {
      formData.set(e, params[e]);
    });

    return axios
      .post(url, formData)
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          throw new Error("cant find cloud content response");
        }
        let { url } = data;
        let cDownloadUrl = url.replace("/upload", "/upload/t_ripple_webapp").replace("http://", "https://");
        return cDownloadUrl;
      })
      .then((dUrl) => pushUrlToQ({ url: dUrl, locationId, rippleId, isMacaroon }))
      .then((pushRes) => {
        return pushRes.data;
      })
      .catch(() => {
        throw Object.assign(new Error(), { errorCode: 2 });
      });
  });
}

export function getPreviewLayout(fileData, brand = "guinness", transformation = "t_guinness_out") {
  return getPreviewUrl(brand).then((res) => {
    let { url, params } = res.data || {};

    if (!url) {
      throw new Error("cant get guinness data");
    }

    let formData = new FormData();
    formData.set("file", fileData);
    Object.keys(params).forEach((e) => {
      formData.set(e, params[e]);
    });

    return axios
      .post(url, formData)
      .then((res) => res.data)
      .then((data) => {
        if (!data) {
          throw new Error("cant find cloud content response");
        }
        let { url } = data;
        let cDownloadUrl = url.replace("/upload", `/upload/${transformation}`);
        return cDownloadUrl;
      })
      .catch(() => {
        throw Object.assign(new Error(), { errorCode: 2 });
      });
  });
}

export function directUpload(fileData, url, tokenHeader, rippleId) {

  if (rippleId) {
    if (!url.endsWith('/')) {
      url += '/'; 
    }
    url += rippleId;
  }

  return axios
    .post(url, fileData, {
      headers: {
        "Content-Type": "image/png",
        Authorization: tokenHeader,
      },
    })
    .then(async (res) => {
      if (res.status === 200 && res.data) {
        let ripplesData = res.data;
        const ordinal = parseInt(ripplesData.ordinal, 10);
        return { ripplesData, ordinal };
      } else {
        throw Object.assign(new Error(), { errorCode: 2 });
      }
    })
    .catch(() => {
      throw Object.assign(new Error(), { errorCode: 2 });
    });
}

export async function surpriseMeFlow({ surpriseImage, content, isMacaroon }) {
  let { url, id: rippleId } = surpriseImage;
  let { locationId, fence } = content;
  return pushUrlToQ({ url, locationId, fence, rippleId, isMacaroon })
    .then((res) => res.data)
    .catch(() => {
      throw Object.assign(new Error(), { errorCode: 2 });
    });
}

export function uploadModule({ locationState = {}, content, tokenHeader, croppedImg, rippleId, isMacaroon }) {
  return new Promise((resolve, reject) => {
    if (locationState.demo) {
      getPreviewLayout(croppedImg).then((data) => resolve(data));
      return;
    }

    if (content.fence && content.fence !== "china") {
      uploadToCloudinary({fileData: croppedImg, locationId: content.locationId, rippleId, isMacaroon})
        .then((mobileQData) => {
          resolve(mobileQData);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      directUpload(croppedImg, content.mobileq, tokenHeader, rippleId)
        .then((mobileQData) => {
          resolve(mobileQData);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
}
