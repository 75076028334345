import React, { useState } from "react";
import Lottie from "react-lottie";
import { sample } from "lodash";

import animationData from "../../assets/lottie/Lottie_logo.json";
import { LoadingModal, LoadingLocations } from "../styles/locationsStyle";
import { Phrase } from "../Phrase";

export function LoadingIndicatorFullScreen() {
  const splashImgs = [
    "https://res.cloudinary.com/ripples/image/upload/v1584261290/webapp/splash/splash1.png",
    "https://res.cloudinary.com/ripples/image/upload/v1584261743/webapp/splash/splash2.png",
    "https://res.cloudinary.com/ripples/image/upload/v1584285157/webapp/splash/splash5.png",
  ];

  const [splashImg] = useState(sample(splashImgs));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <LoadingModal backgroundImage={splashImg}>
      <div>
        <Lottie options={defaultOptions} />
        <LoadingLocations>
          <Phrase Key={"d9c26aef-f23a-44d1-9a68-ed1c4a505b4e"} default={"Loading locations..."} />
        </LoadingLocations>
      </div>
    </LoadingModal>
  );
}
